export default {
  classType: 'components',

  init() {
    this.app.form.forms[this.options.name] = this;

    // Activate ajax handler.
    this.options.ajax &&
      this.el.addEventListener('submit', this.onSubmit.bind(this));
  },

  findElField(fieldName) {
    return this.el.querySelector(`[name="${this.options.name}[${fieldName}]"]`);
  },

  adaptiveResponse(formData) {
    if (formData && formData.errors) {
      // Global errors.
      if (formData.errors.form) {
        let elErrors = this.el.querySelector('.form-errors');
        elErrors.classList.remove('form-errors-no-error');
        elErrors.classList.add('form-errors-has-error');
        elErrors.querySelector('.message-content').innerHTML =
          formData.errors.form;

        this.app.animation.animateElHeightAuto(elErrors, {
          easing: 'easeOutQuint',
        });
      }

      // Fields errors.
      if (formData.errors.fields) {
        for (let fieldName in formData.errors.fields) {
          let elFieldContainer = this.el.querySelector(`[name="${fieldName}"]`)
            .parentNode;
          elFieldContainer.classList.add('has-error');
          elFieldContainer.querySelector(
            '.error-text'
          ).innerHTML = formData.errors.fields[fieldName].join('<br>');
        }
      }
    }
  },

  onSubmit(e) {
    // TODO use a app.form.submit(formName) method.
    e.preventDefault();

    // Remove all errors messages.
    let elErrors = this.el.querySelector('.form-errors');
    elErrors.classList.add('form-errors-no-error');
    elErrors.classList.remove('form-errors-has-error');

    this.el.querySelectorAll('.has-error').forEach((elField) => {
      elField.classList.remove('has-error');
      elField.querySelector('.error-text').innerHTML = '';
    });

    let form = e.currentTarget;
    let formData = new FormData(form);
    let location = window.location;
    let userIsLogged = window['appData'].user.id !== 0;

    // Append which submitter has been used.
    formData.append(
      e.submitter.name,
      'true'
    );

    this.app.modal.modalMain.loadingStart();

    this.app.ajax.send({
      url:
        form.getAttribute('action') ||
        location.pathname + (location.hash ? '#' + location.hash : ''),
      method: 'POST',
      data: formData,
      callback: (data) => {
        data = JSON.parse(data);

        // User log status has changed.
        if (
          data.user &&
          userIsLogged !== data.user.logged &&
          // And we are not on the login form.
          this !== this.app.form.forms.login
        ) {
          this.app.modal.get(data.routes.login, {}, this.app.modal.modalMain);
        }

        // Share form page.
        data.page = {
          instance: this.page,
        };

        if (this.options.inModal && data.redirect) {
          // The login form will redirect to homepage.
          // We can't resubmit form as CSRF token is no more valid after login.
          // We might save form route and content, then display it back with fulfilled value.
          this.app.modal.get(
            data.redirect.url,
            {},
            this.app.modal.modalMain,
            (dataSecond) => {
              delete data.redirect;
              this.app.loadAppData(data, () =>
                this.app.loadAppData(dataSecond)
              );
            }
          );
        } else {
          // Response can include a redirection.
          this.app.loadAppData(data);
        }

        this.app.event.trigger('submitAjaxComplete', data, this.el);
        this.app.modal.modalMain.loadingComplete();

        this.onSubmitComplete(data);
      },
    });
  },

  onSubmitComplete() {
    // To override.
  },
};
